<template lang="pug">
  v-card
    v-card-head
      v-card-head-label
        h2.modal-title {{ 'pages.administration.create_new_position' | translate }}
      v-card-head-toolbar
        v-btn(
          icon
          gray
          @click="onClose") x
    v-card-content
      input.form-control(
        type="text"
        v-model="name"
        :placeholder="'pages.administration.position_name' | translate")
      span.form-text.invalid {{ 'name' | errorText }}
    v-card-actions
      v-btn(
        text
        @click="onClose") {{ 'ui.buttons.cancel' | translate }}
      v-btn(
        @click="onApply") {{ 'ui.buttons.create' | translate }}

</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CreateModal',

  data: () => ({
    name: ''
  }),

  methods: {
    ...mapActions('positions', [
      'createPosition'
    ]),
    ...mapActions('app', [
      'clearErrors'
    ]),

    async onApply () {
      const payload = {
        name: this.name
      }
      let { error } = await this.createPosition(payload)
      if (!error) {
        this.onClose()
      }
    },

    onClose () {
      this.clearErrors()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
</style>
