<template lang="pug">
  v-card
    v-scrollable(
      :bottom-offset="20")
      v-card-head
        v-card-head-label
          h2.title {{ 'ui.labels.positions' | translate}}
          p.subtitle {{ 'infotext.here_you_can_create_edit_and_remove_positions' | translate }}
        v-card-head-toolbar
          input.form-control(
            type="text"
            v-model="search"
            :placeholder="'pages.administration.filter_by_name' | translate")
      v-card-content
        .text-right
          v-btn(
            text
            @click="modals.showCreate = true")
            i.fa.fa-plus
            | {{ 'pages.administration.create_new_position' | translate }}

        positions-table(:search="search")

    v-dialog(
      width="500"
      v-model="modals.showCreate")
      create-modal(@close="modals.showCreate = false")

    v-dialog(
      width="500"
      :value="showEditModal"
      @input="unsetCurrentPosition")
      edit-modal(@close="unsetCurrentPosition")

</template>

<script>
import { mapActions } from 'vuex'

import CreateModal from './modals/CreateModal'
import EditModal from './modals/EditModal'
import PositionsTable from './table/PositionsTable'

export default {
  name: 'Positions',

  components: {
    CreateModal,
    EditModal,
    PositionsTable
  },

  data: () => ({
    search: '',
    modals: {
      showCreate: false
    }
  }),

  methods: {
    ...mapActions('positions', [
      'unsetCurrentPosition'
    ])
  },

  computed: {
    showEditModal () {
      return this.$store.getters['positions/currentPosition'] !== null
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
